export const offers: Array<{
  title: string
  id: string
  image: string
  text: string
}> = [
  {
    title: 'Physiotherapie',
    id: 'physiotherapie',
    image: '',
    text: `Physiotherapeutinnen erbringen Leistungen an und für Personen, um eine grösstmögliche Bewegungs- und Funktionsfähigkeit des Menschen über das ganze Leben hinweg zu entwickeln, zu erhalten und wiederherzustellen. Physiotherapie wird auch dann eingesetzt, wenn die Bewegungs- und Funktionsfähigkeit eines Menschen durch den Alterungsprozess, durch Krankheit oder Unfall beeinträchtigt ist. Physiotherapie behandelt und korrigiert Einschränkungen und Beeinträchtigungen der Bewegungs- und Funktionsfähigkeit sinnvoll und funktionsorientiert. Dabei bezieht sich die Physiotherapie auf die Definition der Weltgesundheitsorganisation (WHO), welche die Gesundheit als einen Zustand des umfassenden körperlichen, geistigen und sozialen Wohlbefindens und nicht lediglich mit dem Freisein von Krankheit und Gebrechen definiert. Auf Basis dieser Definition werden in der Physiotherapie die biologischen, psychologischen, sozialen und individuellen Aspekte einer Krankheit oder Verletzung in Zusammenhang gebracht und ganzheitlich angegangen.`,
  },
  {
    title: 'Sportphysiotherapie',
    id: 'sportphysiotherapie',
    image: '',
    text: `Die Sportphysiotherapie ist ein eigenständiger Bereich innerhalb der Physiotherapie. Zielgruppe sind sowohl Leistungs-/ als auch Freizeitsportler während der Aufbauphase, des Trainings oder in der Rehabilitation nach Verletzungen oder Operationen.

    Der Physiotherapeut kombiniert sein fundiertes Wissen über Körper, physiologische Bewegungsabläufe, Erkrankungen bzw. Verletzungen. Ausserdem gehören Kentnisse über die jeweilige Sportart und deren typischen Verletzungsmuster dazu , um eine optimale Prävention oder Rehabilitation von Verletzungen zu gewährleisten.
    Mit speziellen Übungen und physiotherapeutische Techniken kann man die Belastbarkeit durch die Heilungszeit optimieren.`,
  },
  {
    title: 'Manuelle Therapie',
    image: '',
    id: 'manuelleTherapie',
    text: `Manuelle Therapien und Techniken gehören zu den ältesten medizinischen Handgriffen und existieren seit Jahrtausenden in allen Kulturen auf unterschiedlichste Weise. Man kann sie bis in die Antike zurückverfolgen. Schon Hippokrates war die Traktionstechnik bekannt.

    In der Manuellen Therapie sind die Hände (lateinisch manus = die Hand) das wichtigste Untersuchungs- und Behandlungsinstrument. Mit den Händen ertastet der Therapeut/die Therapeutin sorgfältig Ihre Muskeln, Sehnen und Gelenke. Er/sie erspürt Blockaden und Funktionsstörungen an Ihrer Wirbelsäule und ertastet Verspannungen im Gewebe, die Ihnen Schmerzen bereiten können. Ziel der Manuellen Therapie ist es, eine angemessene Behandlung durchzuführen und diese Störungen zu beheben. Diese Handgriffe werden sowohl zur Schmerzlinderung, als auch zur Mobilisation von Bewegungseinschränkungen eingesetzt. Sie sind schonend und in der Regel schmerzlos.`,
  },
  {
    title: 'Triggerpunkte',
    image: '',
    id: 'triggerpunkte',
    text: `Myofasziale Triggerpunkte sind außerordentlich verbreitet und werden früher oder später zu einem quälenden Bestandteil im Leben jedes einzelnen. Latente Triggerpunkte, die eine gewisse Steifigkeit und ein eingeschränktes Bewegungsausmaß hervorrufen können, sind weit stärker verbreitet als aktive Triggerpunkte. Mit fortschreitendem Alter und abnehmender Aktivität werden Steifigkeit und eingeschränkte Beweglichkeit latenter Triggerpunkte auffallender als der Schmerz. Trotz ihrer Schmerzhaftigkeit sind myofasziale Triggerpunkte nicht direkt lebensbedrohlich, aber der Schmerz kann die Lebensqualität beeinträchtigen.

    Myofaszialer Triggerpunkt:
    
    Eine übererregbare Stelle innerhalb eines verspannten Muskelbündels (Hartspannstrang / „taut band“) in einem Skelettmuskel oder in der Faszie des Muskels, die druckschmerzhaft ist und charakteristischen Übertragungsschmerz („referred pain“), Empfindlichkeit und autonome Phänomene hervorrufen kann. Der Myofasziale Triggerpunkt ist von Haut-, Ligament-, Periost- und nicht muskulären faszialen Triggerpunkten zu unterscheiden.
    
    Aktiver myofaszialer Triggerpunkt:
    
    Ein meist kleiner Herd erhöhter Reizbarkeit in einem Muskel oder in seiner Faszie, der für den Schmerz kennzeichnend ist. Er überträgt in Ruhe und / oder Bewegung ein für den Muskel spezifisches Schmerzmuster. Er ist immer empfindlich, verhindert die volle Verlängerung des Muskels, schwächt den Muskel, überträgt üblicherweise bei Kompression Schmerz, vermittelt eine lokale Zuckungsreaktion („local twitch response“) der Muskelfasern, wenn er entsprechend gereizt wird und führt oft zu speziellen übertragenen autonomen Phänomenen, die im allgemeinen in seiner Schmerzreferenzzone auftreten.
    
    Latenter myofaszialer Triggerpunkt:
    
    Ein meist kleiner Herd erhöhter Reizbarkeit in einem Muskel oder seiner Faszie, der hinsichtlich des spontanen Schmerzes klinisch latent ist. Er ist nur beim Palpieren schmerzhaft.
    
    Assoziierter myofaszialer Triggerpunkt:
    
    Ein kleiner Bereich erhöhter Reizbarkeit in einem Muskel oder seiner Faszie, entstanden als Reaktion auf kompensatorische Überlastung, Verkürzung oder Übertragungsphänomene durch Triggerpunktaktivität in einem anderen Muskel. Sekundär- und Satellit-Triggerpunkte sind Formen der assoziierten myofaszialen Triggerpunkte.
    
    Übertragener Schmerz:
    
    In einem Triggerpunkt entstehender Schmerz, der aber im Abstand davon, oft völlig getrennt von seinem Ursprung, empfunden wird. Das Muster des übertragenen Schmerzes ist reproduzierbar verbunden mit seinem Herkunftsort. Die Verteilung von übertragenem Triggerpunktschmerz stimmt selten mit der vollständigen Ausbreitung eines peripheren Nervs oder Dermatoms überein.
    
    Übertragene Phänomene:
    
    Sensorische und motorische Erscheinungen, zum Beispiel Schmerz, Druckschmerzhaftigkeit, verstärkte Aktivität motorischer Einheiten (Spasmus), Vasokonstriktion, evtl. Vasodilatation und Hypersekretion verursacht durch einen Triggerpunkt, tritt aber normalerweise in einiger Entfernung auf.
    
    Unwillkürliche Ausweichbewegung (jump sign):
    
    Eine allgemeine Schmerzreaktion des Patienten. Als Reaktion auf einen Triggerpunkt angewandten Druck zuckt er zusammen, schreit auf und weicht aus. Hauptschmerzzone: Jener Bereich des Übertragungsschmerzes, der bei einem aktiven Triggerpunkt konstant bei nahezu jedem Patienten vorhanden ist. Nebenschmerzzone: Die Region, in der einige, aber nicht alle Patienten, bei größerer Reizbarkeit eines Triggerpunktes über die Hauptschmerzzone hinaus Übertragungsschmerz empfinden.`,
  },
  {
    title: 'Vitalfeldtherapie',
    image: '',
    id: 'vitalfeldtherapie',
    text: `Die ganzheitliche Medizin betrachtet den Körper als ein Gesamtsystem, das nur dann gut funktionieren kann, wenn alle Vorgänge reibungslos ablaufen. Tritt eine Störung an einem Körperteil auf, sind unter dieser Betrachtungsweise mehr oder weniger alle Körperfunktionen betroffen. 

    Bei einer nur geringen Störung schafft der Körper im Zusammenspiel aller Kräfte einen Ausgleich und behebt den Schaden wieder. Sind jedoch ein oder mehrere Körperteile geschwächt oder arbeiten nicht optimal, macht sich das nicht in jedem Fall sofort mit Schmerzen bemerkbar. Bleiben körperliche Störungen weiterhin unbeachtet, kann das im Laufe der Zeit dazu führen, dass die Belastbarkeit des gesamten Körpers sinkt.
    
    Es ist von Vorteil, vorbeugend zu handeln. In jedem Fall kann die energetische Situation des Körpers helfen, die richtigen Behandlungsprioritäten einzuschätzen.
    
     Die Vitalfeldtherapie arbeitet mit einem Messsystem auf rein physikalischer Grundlage, um die energetischen Zustände des Körpers zu messen und zu analysieren.`,
  },
  {
    title: 'Klassische Massage',
    image: '',
    id: 'massage',
    text: `Die Klassische Massage ist in unserem Alltag eine entspannende und gleichzeitig eine therapeutische Methode. Durch Berührungen kann eine innere Balance hergestellt werden, so dass die eigenen Heilkräfte im Körper in Bewegung gesetzt werden. Die Massage hat nicht nur eine vorbeugendende Wirkung, sondern hilft auch bei akuten Schmerzen und Erkrankungen des Bewegungsapparates. Durch achtsames Erkennen von zusammenhängenden Problemzonen kann die Behandlung Verspannungen und Verhärtungen der Muskulatur lösen, das Gewebe lockern und hilft auch Kopfschmerzen zu lindern. Die Durchblutung aller Gewebeschichten wird angeregt, verbessert den Kreislauf, so dass ein Gefühl von Entspannung, innerer Ruhe und Ausgeglichenheit zugelassen werden kann.

    Die Therapie wird bei verschiedenen Beschwerden angewandt:
    
    Verdauungsbeschwerden
    Rücken-, Gelenkschmerzen und Muskelverspannungen
    Hormonelle Störungen
    Kopfschmerz , Allergien
    Ausleitend bei Infektionen, Entgiftung
    Verbessert den Lymphfluss
    Erschöpfungszustände, Stressabbau
    Rheumatische Beschwerden`,
  },
  {
    title: 'Manuelle Lymphdrainage',
    image: '',
    id: 'lymphdrainage',
    text: `Bei der manuellen Lymphdrainage handelt es sich um eine spezielle Massagetechnik, mit der Lymphstauungen im Gewebe beseitigt werden können.

    Das Lymphgefässsystem des Körpers hat die Aufgabe, die Flüssigkeit (Lymphe) aus dem Gewebe aufzunehmen und in die Blutgefässe abzuleiten. Eine gestörte Funktionsfähigkeit dieses Drainagesystems zum Beispiel infolge von Verletzungen oder nach Operationen kann zu einer Ansammlung von Flüssigkeit in den Zellzwischenräumen führen. Als Folge davon können Schwellungen (Ödeme) entstehen. Die manuelle Lymphdrainage ist eine entstauende Massnahme, mit deren Hilfe die Motorik der Lymphgefässe angeregt und damit der Abtransport überschüssiger Gewebsflüssigkeit erleichtert werden soll. Darüber hinaus hat die Lymphdrainage eine stark entspannende und beruhigende Wirkung und sie regt das Immunsystem an.`,
  },
  {
    title: 'Fussreflexzonen Therapie',
    image: '',
    id: 'fussreflexzonen',
    text: `Die Fussreflexzonenmassage wurde schon vor vielen tausend Jahren in Indien und China angewandt und ist heute noch eine unterstützende Therapieform. Alle unsere Körperteile, Organe und Drüsen spiegeln sich in unseren Füssen wieder. Durch eine Tastdiagnose, kann spezifisch auf das Problem eingegangen werden. Die Behandlung wird da angesetzt, wo sie bei Beschwerden, einer Krankheit oder Operation am besten unterstützend wirken kann. Mit dem Berühren und Arbeiten an den Füssen lösen wir Reize aus, die reflektorisch unsere Organe besser durchbluten, Spannungen abbauen und den ganzen Organismus harmonisieren und stabilisieren.

    Die Therapie wird bei verschiedenen Beschwerden angewandt:
    
    Verdauungsbeschwerden
    Rücken –Gelenkschmerzen und Muskelverspannungen
    Hormonelle Störungen
    Kopfschmerzen , Allergien
    Ausleitend bei Infektionen, Entgiftung
    Verbessert den Lymphfluss
    Erschöpfungszustände, Stressabbau
    Rheumatische Beschwerden`,
  },
  {
    title: 'Elektrotherapie',
    image: '',
    id: 'elektrotherapie',
    text: `Die Elektrotherapie gehört zu den Verfahren der physikalischen Therapie. Mit Hilfe von elektronischen Geräten fliessen elektrische Ströme unterschiedlicher Frequenz durch den Körper oder Körperteile, um Krankheiten zu behandeln.

    Heute wird die Elektromedizin in praktisch allen klinischen Bereichen angewendet. Ein Teilbereich der Elektromedizin ist die Elektrotherapie, die zu den physiotherapeutischen Behandlungsmethoden zählt.
    
    Im Rahmen der Elektrotherapie kommen Ströme mit unterschiedlichen Frequenzen zum Einsatz. Man spricht von Niederfrequenztherapie, im Bereich von 0-1000 Hz. Für die Mittelfrequenztherapie arbeitet man im Bereich von 1000-100’000 Hz. Im Hochfrequenztherapiebereiche werden nie mehr als 100’000 Hz verwendet. Die Ströme werden dem Körper über Elektroden in einem Wasserbad oder direkt auf der Haut zugeführt.
    
    Im Organismus hat der Strom eine schmerzlindernde und durchblutungsfördernde Wirkung. Je nach Therapieform kann die Behandlung ausserdem entweder zu einer Entspannung oder zu einer Erregung der behandelten Muskulatur beitragen.
    
    Aufgrund der guten Leitfähigkeit verläuft der Stromfluss entlang der Blut- und Lymphgefässe. Tiefere Strukturen im Körper werden durch die Elektrotherapie nur schwer erreicht. Weitere gute Stromleiter sind: Hirnflüssigkeit, Harn, die inneren Organe und Muskulatur. Schlechte Stromleiter sind: Fettgewebe, Gelenkkapseln, Sehnen, Knochen und bestimmte Nerven. Nichtleiter sind Haare und Nägel sowie die Hornschicht trockener Haut.`,
  },
  {
    title: 'Ultraschall',
    image: '',
    id: 'ultraschall',
    text: `Ultraschall ist eine Anwendung, die im weitesten Sinn auch zur Elektrotherapie zählt. Hierbei wird der Strom über hochfrequente Schallwellen von ca. 1 MHz in den Körper übertragen.

    Der Ultraschall wird über einen Schallkopf mit ca. 5-6cm2 Fläche über ein Kontaktgel auf die erkrankte Region aufgetragen und dabei kreisend bewegt. Der Schall wird dabei kontinuierlich oder pulsierend abgegeben. Beim pulsierenden Schall entsteht weniger Wärmewirkung und er ist dadurch weniger intensiv.
    
    Die Wirkung zeigt sich in einer verstärkten Durchblutung und einem verbesserten Zellstoffwechsel. Die Schmerzschwelle wird damit angehoben. Somit können Verletzungen in Muskel- und Sehnengewebe schneller heilen. Ultraschall wird häufig kombiniert mit der klassischen Elektrotherapie eingesetzt.
    
    Ultraschall wird eingesetzt:
    
    paravertebral neben der Wirbelsäule
    an den grossen Gelenken
    bei Muskelverletzungen
    bei Sehnenreizung oder- verletzungen.
    Wärmeanwendungen`,
  },
  {
    title: 'Kinesiotape',
    image: '',
    id: 'kinesiotape',
    text: `Kinesiotaping soll Schmerzen lindern und die Durchblutung in den behandelten Arealen verbessern. Es zielt vor allem auf muskuläre Probleme, die eine Reihe von Problemen und Erkrankungen hervorrufen können.

    Hierzu werden äußerst elastische, einige Zentimeter breite, atmungsaktive und auf Baumwollbasis hergestellte Klebestreifen auf die Haut in der Nähe schmerzhafter Bereiche aufgebracht.
    
    Erklärt wird die Wirkung damit, dass die Tapes die Haut unter Spannung setzen und damit die obersten Hautschichten leicht angehoben und bei jeder Bewegung leicht massiert werden. Dadurch soll der Blutfluss und Lymphabfluss erleichtert und gefördert werden, so dass verletzte Regionen besser durchblutet werden, um den körpereigenen Erholungsprozess zu fördern.
    
    Außerdem erzeugen die Entzündungsreaktionen und Verletzungen die Ansammlung von Blut oder Gewebeflüssigkeit oder eine Schwellung und damit Druck auf die zwischen den Hautschichten sitzenden Schmerzrezeptoren. Durch die elastischen Tapes wird der Druck auf die Rezeptoren reduziert, was zu einer Schmerzlinderung führen kann.
    
    Dies ermöglicht es, die betroffenen Körperteile wieder normal zu bewegen und hat zur Folge, dass weniger Schonhaltungen eingenommen werden und schmerzhafte Verkrampfungen vermieden werden. Die Kinesiotapes bieten eine gewisse Stabilität, lenken die Bewegungsrichtung und vor allem fördern sie die Wahrnehmung der Muskeln, der eigenen Belastbarkeit und Beweglichkeit, die sogenannte Propriozeption. Dadurch wird eine bessere Bewegung und eine Verbesserung des Muskeltonus (Muskelspannung) bewirkt. Durch die elastischen Eigenschaften schränken sie den Bewegungsumfang des Gelenkes nicht ein.`,
  },
  {
    title: 'Craniosacral Therapie',
    image: '',
    id: 'cranio',
    text: `Craniosacral Therapie ist eine Körpertherapie zur Mobilisierung der Selbstheilungskräfte. Sie hilft dem Körper, sich an seinem ursprünglichen Bauplan auszurichten, sozusagen sein Gedächtnis von seinem gesunden Originalzustand aufzufrischen.

    Dieser Prozess wird eingeleitet, indem die Hände an den bekleideten Körper der Klientin/ des Klienten gelegt werden. Wir lassen ihn erzählen, spüren seine Rhythmen und inneren Bewegungen. Dies geschieht auf der Ebene des Liquors (Hirnrückenmarkflüssigkeit) sowie der Nerven, Muskeln, Knochen, Organe und des Bindegewebes. Dabei stellt sich ein tiefer Entspannungszustand ein und der Körper beginnt sich mit therapeutischer Unterstützung neu auszurichten, was von der Klientin/ dem Klienten ganz unterschiedlich, z.B. als Wellen durch den Körper, Hitze, Schmerzlinderung, Änderung der Körperwahrnehmung erlebt wird.
    
    Wir arbeiten nach der biodynamischen Methode.
    
    Für wen ist die Craniosacral Therapie geeignet?
    
    Craniosacral Therapie ist eine sanfte, liebevolle Behandlungsform, die sich für Erwachsene ebenso gut wie für Babys, Kinder und Jugendliche eignet. Sie kann helfen, sobald etwas aus dem Gleichgewicht geraten ist, sich Unwohlsein, Krankheit, Schmerzen oder Unruhe breit machen.
    
    Dazu gehören:
    
    Bewegungsapparat, Muskeln, Gelenke, Nerven
    Schlafstörungen, Nervosität, Verspannungen
    Kopfschmerzen, Migräne, Zähneknirschen
    Gereiztheit, Ungeduld, Sorgen wälzen, Erschöpfung
    Babys: Unruhe, Schiefhals, Schreikinder, Verdauungsprobleme
    Mehr Informationen dazu erhalten sie auf der Homepage von Craniosuisse.`,
  },
  {
    title: 'Dry Needling',
    image: '',
    id: 'dryNeedling',
    text: `Dry Needling ist eine wirksame Ergänzung zur manuellen Triggerpunkt-Therapie. Hierbei wird mit einer dünnen Nadel ohne Medikament (deshalb der Name dry = trocken) ganz präzise in den Triggerpunkt gestochen, welcher für die aktuellen Beschwerden verantwortlich ist. 
    Dadurch werden die Verkrampfungen gelöst, die lokale Durchblutungssituation verbessert und die lokalen Entzündungsreaktionen gesenkt. Das «dry needling» wird bei der Behandlung von Schmerzsyndromen, in der Sportphysiotherapie und der orthopädischen Rehabilitation angewendet.
    Verschiedene Studien haben bewiesen, dass es nicht nötig ist Substanzen in einen Triggerpunkt hineinzuspritzen, damit er abheilt, sondern dass es der präzis geführte Stich an sich ist. Deshalb hat sich der Begriff des «trockenen Nadelns» ergeben. 
    Das «dry needling» wird seit 1996 in der Schweiz praktiziert und systematisch unter Fachpersonal eingeführt. Beim «dry needling» werden die Triggerpunkte unter hygienischen Bedingungen mit dünnen, sterilen Einwegakupunkturnadeln angestochen. Diese Technik hat aber nichts mit Akupunktur zu tun, ausser dass das gleiche Werkzeug verwendet wird. Der Stich durch die Haut ist kaum spürbar. Wenn man den Triggerpunkt trifft zieht sich der Hartspannstrang in dem sich der Triggerpunkt befindet kurz aber heftig zusammen. Dieses Auslösen der sogenannten lokalen Zuckungsantwort kann als unangenehm empfunden werden, ist aber ein Muss bei der intramuskulären Stimulation (IMS). Trotzdem wird das «dry needling» von vielen Patienten der manuellen Therapie vorgezogen. Hat ein Patient jedoch Mühe mit dem intramuskulären  «dry needling», so kann versucht werden mit einer oberflächlichen Anwendung, dem sogenannten superfiziellen «dry needling», bzw. der superfiziellen Afferenz-Stimulation (SAS) oder mit den manuellen Techniken der Triggerpunkt-Therapie die myofaszialen Beschwerden zu behandeln.`,
  },
  {
    title: 'Fasziendistorsionsmodell',
    image: '',
    id: 'faszienDistorsionsModell',
    text: `Der Begriff setzt sich aus den beiden lateinischen Begriffen „Fascia“ (Bündel) und „Distorsio" (Verrenkung/Verdrehung) zusammen.
    Bei dieser Technik werden Verdrehungen in den Faszien gelöst / "ausgebügelt" und somit funktionelle Beschwerden beseitigt.
    Nach einer eingehenden Anamnese kann der Therapeut gezielt eine oder mehrere der verschiedenen Techniken anwenden und somit schmerzhafte Störungen lindern.`,
  },
  {
    title: 'Osteopathie',
    image: '',
    id: 'osteopathie',
    text: `Bei der Osteopathie handelt es sich um ein manuelles Verfahren zur  Behandlung von Bewegungsapparat, den Organen und Faszien. Die Osteopathie geht davon aus, dass der Körper – so denn keine Blockaden in diesen Bereichen vorliegen – in der Lage ist, sich selbst zu heilen.`,
  },
  {
    title: 'Pilates',
    image: '',
    id: 'pilates',
    text: `Pilates ist ein ganzheitliches Ganzkörpertraining, bei dem Atmung und Bewegung in Einklang gebracht werden. Durch die Anspannung der tiefen Bauch-, Rücken- und Beckenbodenmuskulatur wird der Rumpf stabilisiert und damit das Powerhouse aktiviert.`,
  },
  {
    title: 'TCM Therapie',
    image: '',
    id: 'tcm',
    text: `Bei der Akupunktur soll durch Manipulation von Energiepunkten der Fluss der Energie ausgeglichen werden. In diesem Fall arbeite ich mit dünnen Nadeln. Das Einstechen wird meist als schmerzlos empfunden.
    Die Ohrakupunktur Behandlung erfolgt mit Nadeln an spezifischen Punkten an den Ohren.
    Die Moxibastion oder Moxibustion ist eine Therapiemethode der Traditionellen Chinesischen Medizin (TCM), bei der Akupunkturpunkte durch glimmendes Beifuss-Kraut erwärmt werden.
    Durch Schröpfgläser wird auf bestimmten Hautpartien ein Unterdruck erzeugt. Oft wende ich diese Therapieform auf dem Rücken an. Dadurch können blau-violette Hautverfärbungen entstehen
    Verspannungen, Kopfschmerz und andere Leiden werden meist mit Medikamenten behandelt, was häufig Nebenwirkungen zur Folge hat. Gua Sha bietet eine sanfte Alternative, diese Beschwerden zu lindern. Bei dieser Methode wird mit einem Porzellanlöffel über eine Hautpartie geschabt, hauptsächlich auf dem Rücken. Je nach der Diagnose werden die entsprechenden Stellen mit schwächerem oder stärkerem Druck und unterschiedlicher Dauer behandelt. Dabei werden lokale Schmerzpunkte beruhigt und der Energiefluss in den betroffenen Meridianen ausgeglichen. Oft sind nach der Behandlung blau-violette Verfärbungen unter der Haut zu erkennen.
    Die Elektro Akupunktur Methode ist mit der Akupunktur identisch. Die Manipulation geschieht jedoch durch feine, elektrische Impulse.`,
  },
]
